import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Choice wrap=function",
        "componentName": "Choice",
        "wrap": "function"
      }}>{`() => {
  const [checked, setChecked] = React.useState('3');

  return (
    <div>
      <Box p={2}>
        <Choice
          value="1"
          name="choice"
          title="Choose 1"
          checked={checked === '1'}
          onChange={({ target }) => setChecked(target.value)}
        />
      </Box>
      <Box p={2}>
        <Choice
          value="2"
          name="choice"
          title="Choose 2"
          checked={checked === '2'}
          onChange={({ target }) => setChecked(target.value)}
        />
      </Box>
      <Box p={2}>
        <Choice
          value="3"
          name="choice"
          title="Choose 3"
          checked={checked === '3'}
          onChange={({ target }) => setChecked(target.value)}
        />
      </Box>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Choice" mdxType="Props" />
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The message is meant as a sort of subtitle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Choice value="message" name="message" title="I've got" message="message" />
`}</code></pre>
    <h2 {...{
      "id": "children",
      "style": {
        "position": "relative"
      }
    }}>{`Children`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The content only shown when a specific choice is checked.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [checked, setChecked] = React.useState('children1');
  return (
    <div>
      <Box p={2}>
        <Choice
          value="children1"
          name="children"
          title="I've got children when you check me"
          checked={checked === 'children1'}
          onChange={({ target }) => setChecked(target.value)}
        >
          Kiekeboo!
        </Choice>
      </Box>
      <Box p={2}>
        <Choice
          value="children2"
          name="children"
          title="Me too"
          checked={checked === 'children2'}
          onChange={({ target }) => setChecked(target.value)}
        >
          Kiekeboo too!
        </Choice>
      </Box>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Marking a Choice `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Choice value="disabled" name="disabled" title="I am disabled" disabled />
`}</code></pre>
    <h2 {...{
      "id": "image",
      "style": {
        "position": "relative"
      }
    }}>{`Image`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Visually provide extra information to the choice by passing an image, please
don't forget the alt.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Choice
  value="image"
  name="image"
  title="Meow"
  caption="My image needs to be 64px wide, and 64px high."
  imageSrc="http://placekitten.com/64/64"
  imageAlt="A cat."
/>
`}</code></pre>
    <h2 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}>{`Info`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Provide additional context to the Choice.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Choice value="1" name="info" title="A bit of" info="info on top" />
`}</code></pre>
    <h2 {...{
      "id": "title",
      "style": {
        "position": "relative"
      }
    }}>{`Title`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`What's this Choice all about?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Choice value="title" name="title" title="A title is required" />
`}</code></pre>
    <h2 {...{
      "id": "type",
      "style": {
        "position": "relative"
      }
    }}>{`Type`}</h2>
    <p><inlineCode parentName="p">{`default: radio`}</inlineCode></p>
    <p>{`Switch between radio and checkbox functionallity.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box p={2}>
  <Choice
    value="radio1"
    name="radio"
    title="Type can be radio"
    type="radio"
  />
</Box>
<Box p={2}>
  <Choice
    value="radio2"
    name="radio"
    title="like in radio"
    type="radio"
  />
</Box>
<Box p={2}>
  <Choice
    value="checkbox"
    name="checkbox"
    title="Or checkbox"
    type="checkbox"
  />
</Box>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      